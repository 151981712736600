<template>
  <div class="container-register">
    <h3 class="text-center">
      {{ method == "edit" ? "تعديل بيانات المعدة" : "أضافة معدة جديدة" | translate }}
    </h3>
    <hr />
    <br /><br /><br />
    <div class="row g-3">
      <div class="col-md-6">
        <img
          src="../../src/assets/imgs/device.jpg"
          class="img-fluid"
          alt=""
          width="600"
          height="auto"
        />
      </div>
      <div class="col-md-6 bg-light">
        <div class="form">
          <div>
            <qr-code
              :id="id"
              :name="`${id}-${category}-${location}-${code}.png`"
            ></qr-code>
            <hr />
          </div>
          <br />
          <p class="">{{ "تأكد من صحة البيانات المدخلة" | translate }}</p>
          <hr />
          <div class="row register-form g-3">
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ "نوع المعدة" | translate }}</label>
                <select class="form-control" v-model="category" required>
                  <option v-for="cat in categories" :key="cat.name" :value="cat.name">
                    {{ cat.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ "الموقع" | translate }}</label>
                <select class="form-control" v-model="location" required>
                  <option
                    v-for="location in locations"
                    :key="location._id"
                    :value="location.name"
                  >
                    {{ location.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{ "كود المعدة" | translate }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="code"
                  placeholder="equipment type"
                />
              </div>
            </div>
            <hr />
            <div class="col-12">
              <loading v-if="loading" />
            </div>
          </div>
          <!-------->
          <div class="row justify-content-center">
            <div class="col-md-5 center">
              <button
                @click="submit"
                :disabled="!complete"
                class="btn btn-success center"
              >
                {{ method == "edit" ? "تعديل البيانات" : "أضافة معدة جديدة" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  created() {
    if (this.$route.params.id) {
      this.method = "edit";
    } else this.method = "add";

    if (this.method === "edit") {
      axios.get("/getDeviceById/" + this.$route.params.id).then((res) => {
        if (res.data.err) return this.$store.dispatch("writeMessage", res.data.err);
        const device = { ...res.data.device };
        this.id = device._id;
        this.category = device.category;
        this.location = device.location;
        this.code = device.code;
      });
    }
  },
  components: {},
  data() {
    return {
      method: "add",
      loading: false,
      id: null,
      category: "",
      location: "",
      code: "",
    };
  },
  computed: {
    categories() {
      return this.$store.getters.categories;
    },
    locations() {
      return this.$store.getters.locations;
    },
    complete() {
      return this.category.length > 1 && this.code.length > 1 && this.location.length > 1;
    },
  },
  methods: {
    submit() {
      if (!this.complete) return;
      this.loading = true;
      const device = {
        category: this.category,
        location: this.location,
        code: this.code.toLowerCase(),
      };
      const method = this.method == "edit" ? "/editDevice/" + this.id : "/addDevice";
      axios.post(method, device).then((res) => {
        this.loading = false;
        if (res.data.err) {
          return this.$store.dispatch("writeMessage", res.data.err);
        }
        if (res.data.device) this.id = res.data.device._id;
        this.$store.dispatch("writeMessage", "تم اضافة المعدة بنجاح");
      });
    },
    ////////////////////////////////
    downloadQrCode() {
      // const qr = this.$refs.qrcode;
      const qr = document.getElementById("qrcode");
      const canvas = qr.getElementsByTagName("canvas")[0];
      const url = canvas.toDataURL("image/png");
      /////////////
      // const blob = new Blob([image])
      // const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.download = `${this.id}-${this.category}-${this.location}-${this.code}.png`;
      a.href = url;
      a.click();
      window.URL.revokeObjectURL(url);
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  direction: rtl;
  text-align: right;
}
.container-register {
  padding: 20px;
  // background: rosybrown;
  @media (max-width: 500px) {
    padding: 60px 2px;
  }
}
.form {
  width: 100%;
  h3 {
    color: rgba(85, 85, 85, 0.782);
  }
  label {
    color: #666;
  }
}
.form-group {
  .error-msg {
    color: #dc3545;
    margin-top: 5px;
    &::before {
      content: "\f06a";
      font: normal normal normal 14px/1;
      font-size: 14px;
      font-size: inherit;
      text-rendering: auto;
      -moz-osx-font-smoothing: grayscale;
      margin-left: 5px;
    }
  }
}
.download {
  color: rgb(13, 112, 51);
  // text-decoration: none;
}
</style>
